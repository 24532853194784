import { render, staticRenderFns } from "./App.vue?vue&type=template&id=962ea404&scoped=true&lang=pug&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=962ea404&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "962ea404",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';import Scroll from 'quasar/src/directives/Scroll.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QToolbar,QHeader,QBtn,QSpace,QMenu,QList,QItem,QPageContainer,QPageSticky,QFooter,QImg,QDialog,QCard,QCardSection,QInput,QSeparator,QCardActions,QIcon,QInnerLoading});qInstall(component, 'directives', {Scroll,ClosePopup});
